// router/index.js

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/p'
  },
  {
    path: '/p',
    component: () => import(/* webpackChunkName: "OutView" */ '../views/OutView.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue')
      },
      {
        path: 'news',
        component: () => import(/* webpackChunkName: "InnerView" */ '../views/InnerView.vue'),
        children: [
          {
            path: '',
            redirect: '/p/news/notice'
          },
          {
            path: ':category/',
            component: () => import(/* webpackChunkName: "NewsPost" */ '../views/news/NewsBoard.vue'),
            props: true
          },
          {
            path: ':category/post/:id',
            component: () => import(/* webpackChunkName: "NewsPost" */ '../views/news/NewsPost.vue'),
            props: true
          }
        ]
      },
      {
        path: 'about',
        component: () => import(/* webpackChunkName: "InnerView" */ '../views/InnerView.vue'),
        children: [
          {
            path: '',
            redirect: '/p/about/detail'
          },
          {
            path: 'detail',
            component: () => import(/* webpackChunkName: "AboutDetail" */ '../views/about/AboutDetail.vue')
          },
          {
            path: 'symbol',
            component: () => import(/* webpackChunkName: "AboutSymbol" */ '../views/about/AboutSymbol.vue')
          },
          {
            path: 'intro',
            component: () => import(/* webpackChunkName: "AboutIntro" */ '../views/about/AboutIntro.vue')
          },
          {
            path: 'history',
            component: () => import(/* webpackChunkName: "AboutHistory" */ '../views/about/AboutHistory.vue')
          },
          {
            path: 'members',
            component: () => import(/* webpackChunkName: "AboutMembers" */ '../views/about/AboutMembers.vue')
          }
        ]
      },
      {
        path: 'event',
        component: () => import(/* webpackChunkName: "InnerView" */ '../views/InnerView.vue'),
        children: [
          {
            path: '',
            redirect: '/p/event/academy'
          },
          {
            path: 'academy',
            component: () => import(/* webpackChunkName: "EventAcademy" */ '../views/event/EventAcademy.vue')
          },
          {
            path: 'seminar',
            component: () => import(/* webpackChunkName: "EventSeminar" */ '../views/event/EventSeminar.vue')
          },
          {
            path: 'paper',
            component: () => import(/* webpackChunkName: "EventPaper" */ '../views/event/EventPaper.vue')
          },
          {
            path: 'lecture',
            component: () => import(/* webpackChunkName: "EventLecture" */ '../views/event/EventLecture.vue')
          }
        ]
      },
      {
        path: 'research',
        component: () => import(/* webpackChunkName: "InnerView" */ '../views/InnerView.vue'),
        children: [
          {
            path: '',
            redirect: '/p/research/list'
          },
          {
            path: 'list',
            component: () => import(/* webpackChunkName: "ResearchList" */ '../views/research/ResearchList.vue')
          },
          {
            path: 'detail/:id',
            component: () => import(/* webpackChunkName: "ResearchDetail" */ '../views/research/ResearchDetail.vue'),
            props: true
          },
          {
            path: 'post',
            component: () => import(/* webpackChunkName: "ResearchPost" */ '../views/research/ResearchPost.vue')
          }
        ]
      },
      {
        path: 'faq',
        component: () => import(/* webpackChunkName: "InnerView" */ '../views/InnerView.vue'),
        children: [
          {
            path: '',
            redirect: '/p/faq/contact'
          },
          {
            path: 'contact',
            component: () => import(/* webpackChunkName: "FaqView" */ '../views/FaqView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


export default router;
