import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

// Vuetify 관련 import 추가
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Vuetify 스타일 가져오기
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import './assets/styles/main.scss';


// Vuetify 인스턴스 생성
const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify); // Vuetify 적용

app.mount('#app');
