<template>
  <div id="app" :class="[{ 'small-screen': isSmallScreen, 'small-nav': isSmallNav }]">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';


export default {
  name: 'App',
  data() {
    return {
      isSmallScreen: false,
      isSmallNav: false,
    };
  },
  computed: {
  },
  created() {
    // this.$store.dispatch('auth/autoLogin')
  },
  mounted() {
    this.applyDynamicStyles();  
    window.addEventListener('resize', this.applyDynamicStyles);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.applyDynamicStyles);
  },
  methods: {
    ...mapActions('category', ['updateCurrentPath']),
    applyDynamicStyles() {
      const width = window.innerWidth;
      const s_width = screen.width;
      const body = document.body;
      this.isSmallScreen = width < 1300 * (s_width / 1920);
      this.isSmallNav = width < 1100 * (s_width / 1920);
      if (s_width != 1920) {
        body.style.zoom = (s_width / 1920) * 100 + '%';
      }
    },
  },
  watch: {
      '$route'(to) {
        const category = to.path.split('?')[0].split('/').slice(1);
        this.updateCurrentPath(category);
      },
    },
};


</script>

<style>
*{
  margin:0;
}
.post-img {
  width: 80%;
}
</style>
