const notice = { 
  title: "공지사항", 
  link: "notice" , 
  detail: [{
    title: "게시글", link: "post", isNotLink: true
  }]
};

const defaultCategory = {
  title: '',
  link: '',
  detail: [],
  isNotNav: false,
};

const state = {
  categories: {
    p: {
      research: {
        ...defaultCategory,
        title: "연구 과제",
        detail: [
          { title: "목록", link: "list" },
          { title: "상세", link: "detail", isNotLink: true },
        ],
        link: "research",
        isNotNav: true
      },
      news: {
        ...defaultCategory,
        title: "뉴스",
        detail: [
          { ...notice },
          { title: "새소식", link: "news" },
          { title: "언론보도", link: "media" }
        ],
        link: "news"
      },
      about: {
        ...defaultCategory,
        title: "NUPS 소개",
        detail: [
          { title: "넙스 소개", link: "detail" },
          { title: "넙스 상징물", link: "symbol" },
          { title: "인사말", link: "intro" },
          { title: "설립목적 및 연혁", link: "history" },
          { title: "넙스 구성원", link: "members" }
        ],
        link: "about"
      },
      event: {
        ...defaultCategory,
        title: "행사",
        detail: [
          { title: "학술행사", link: "academy" },
          { title: "세미나", link: "seminar" },
          { title: "논문", link: "paper" },
          { title: "대중강연", link: "lecture" },
        ],
        link: "event"
      },
      faq: {
        ...defaultCategory,
        title: "문의사항",
        link: "faq",
        detail: [
          { title: "연락처", link: "contact" }
        ],
        isNotNav: true
      },
    }
  },
  currentPath: [],
};


const mutations = {
  setCurrentPath(state, pathArray) {
    state.currentPath = pathArray;
  }
};

const actions = {
    updateCurrentPath({ commit }, pathArray) {
        commit('setCurrentPath', pathArray);
    },
};

const getters = {
  categories: (state, getters) => state.categories[getters.currentPublic] || {},
    currentPublic: (state) => state.currentPath[0] || '',
    currentCategory: (state) => state.currentPath[1] || '',
    currentSubCategory: (state) => state.currentPath.slice(2),
    currentCategoryTitle: (_state, getters) => {
        return {
          title: getters.categories[getters.currentCategory]?.title,
          link: getters.categories[getters.currentCategory]?.link} || {title: '', link: ''};
    },
    currentSubCategoryTitle: (state, getters) => {
        if (getters.currentSubCategory.length === 0) {
            return '';
        }
        const subCategoryList = getters.currentSubCategory
        const result = []
        let subCategory = undefined

        subCategoryList.forEach(el => {
            let subCategoryLink = el;
            const a = subCategory ? subCategory : getters.categories[getters.currentCategory]; // 계층적 구조
            // if(!a?.detail){
            //   return result;
            // }
            subCategory = a?.detail?.find(
                item => item.link === subCategoryLink
            );
            if(Number(subCategoryLink) < 10) { 
              subCategoryLink = '0' + subCategoryLink
            }
            if(!subCategory){
              subCategory = {
                title: subCategoryLink,
                link: subCategoryLink
              }
            }

            result.push({
              title: subCategory.title,
              link: subCategory.link,
              isNotLink: subCategory.isNotLink 
            });
        });
        return result;
    },
    checkNotNav: (_state, getters) => getters.categories[getters.currentCategory].isNotNav 
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};